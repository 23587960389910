//變形kd
export function ok1788customized3(rsv, k, d) {
    const in0 = rsv;
    // 3根Κ棒
    const in1 = k;
    // 3根Κ棒
    const in2 = d;
    const close = this.PineJS.Std.close(this._context);
    const high = this.PineJS.Std.high(this._context);
    const low = this.PineJS.Std.low(this._context);
    const high_array = this._context.new_var(high);
    const low_array = this._context.new_var(low);
    //高
    const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
    //低
    const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
    //RSV
    const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
    //K%
    const vk_array = this._context.new_var();
    const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
    if (isNaN(s)) {
        vk_array.set(0);
    }
    else {
        vk_array.set(s);
    }
    //D%
    const vd_array = this._context.new_var();
    const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
    if (isNaN(q)) {
        vd_array.set(0);
    }
    else {
        vd_array.set(q);
    }
    return [vk_array.get(0) - 50, vd_array.get(0) - 50, vk_array.get(0) - vd_array.get(0)];
}
