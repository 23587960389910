//變形macd
export function ok1788customized1(length) {
    const c = this.PineJS.Std.close(this._context);
    const nc = this._context.new_var(c);
    // 收盤價指數移動平均線
    const ma1 = this.PineJS.Std.ema(nc, length, this._context);
    // 收盤價簡單移動平均線
    const ma2 = this.PineJS.Std.sma(nc, length, this._context);
    const diff = ma1 - ma2;
    return diff;
}
